<script lang="ts">
import { onMount, onDestroy, beforeUpdate } from 'svelte';
import { fade, fly } from 'svelte/transition'
import type { ModalData } from './types'
import ModalContent from './ModalContent.svelte'
import merge from 'deepmerge'

const loaderDelay = 100 // in ms, Loader nicht sofort zeigen, um visuelles Zuckeln auf schnellen Systemen zu vermeiden

export let data: ModalData
export let closeModal
export let updateRef

const animationDuration = 300

let modalElement
let bgElement
let contentElement
let originalParent

let showLoader = false
let asyncProps = data.asyncPropsFn ? null : {}
let modalData: ModalData = data

function handleBgClick(e) {
    if (e.target === bgElement) {
        e.stopPropagation() && e.preventDefault()

        close()
    }
}

function close() {
    closeModal(data.id)
}

onMount(async () => {
    updateRef(data.id, { close })
    data.onOpen && data.onOpen(modalElement)

    if (data.asyncPropsFn) {
        setTimeout(() => {
            showLoader = !asyncProps
        }, loaderDelay)

        try {
            asyncProps = await data.asyncPropsFn()
        }
        catch(e) {
            throw new Error('Request failed: ' + e)
        }

        modalData = merge(data, asyncProps)
    }
})

onDestroy(() => {
    data.onClose && data.onClose(modalElement)
})

function getModalClasses() {
    const classes = [ 'modal-dialog' ]

    if (data.size) {
        classes.push(`modal-${data.size}`)
    }

    return classes.join(' ')
}

</script>

<div bind:this={bgElement} class="modal-wrapper" on:click={handleBgClick} transition:fade={{ duration: animationDuration }}>
    <div bind:this={modalElement} class={`modal modal-${data.type}`} transition:fly={{ y: 100, duration: animationDuration }} role="dialog" id={`modal-${data.id}`}>
        <div class={getModalClasses()} role="document">
            <div class="modal-content">

                {#if data.asyncPropsFn && !asyncProps}
                    {#if showLoader}
                        <div class="loader">
                            <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <circle class="path" fill="none" stroke-width="6" stroke-linecap="butt" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                    {/if}
                {:else}
                    <ModalContent data={modalData} closeModal={closeModal} />
                {/if}
            </div>
        </div>

        {#if modalData.description}
            <div class="modal__description">
                {@html modalData.description}
            </div>
        {/if}
    </div>
</div>
